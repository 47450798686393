<template>
  <BaseDialog
    v-model="showStatusMappingInfoDialog"
    title="Status Mapping Info"
    width="60%"
    @closeDialog="showStatusMappingInfoDialog = false"
  >
    <template #dialogContent>
      <v-card elevation="0" class="ma-0 pa-0">
        <v-card-text class="ma-0 pa-0">
          <v-row no-gutters class="ma-0 pa-0">
            <v-col>
              <BaseListLayout
                name="statusMappingList"
                ref="statusMappingList"
                :table-header="columns"
                :table-data="statusList"
                :context="context"
                :total="totalItems"
                :gridStyle="gridStyle"
                :showColumnSelection="false"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from "@/components/BaseComponents/BaseDialog.vue";
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";

export default {
  name: "OrganizationVendorStatusInfoDialog",
  components: {
    BaseDialog,
    BaseListLayout,
  },
  props: {
    value: {
      type: Boolean,
    },
    organizationVendorID: {
      type: Number,
    },
  },
  data() {
    return {
      totalItems: 0,
      gridStyle: {
        width: "100%",
        height: "60vh",
      },
      statusList: [],
      columns: [
        {
          headerName: "Vendor",
          field: "vendor_name",
        },
        {
          headerName: "Organization Status",
          field: "organization_status",
        },
        {
          headerName: "Vendor Status",
          field: "vendor_status",
        },
        {
          headerName: "Formatted Vendor Status",
          field: "formatted_vendor_status",
        },
        {
          headerName: "Vendor Status Code",
          field: "vendor_status_code",
        },
      ],
    };
  },
  watch: {
    showStatusMappingInfoDialog(val) {
      if (val) {
        this.getOrganizationVendorStatusMappingData();
      }
    },
  },
  computed: {
    showStatusMappingInfoDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    getOrganizationVendorStatusMappingData() {
      this.$api.organizationVendor
        .getOrganizationVendorStatusDetails(this.organizationVendorID)
        .then((res) => {
          this.totalItems = res.count;
          this.statusList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style></style>
