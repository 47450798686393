<template>
  <v-row>
    <v-col cols="12">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            v-if="params.data.is_success == 'No'"
            icon
            @click="editAndBook()"
          >
            <v-icon color="green">mdi-pencil</v-icon>
          </v-btn>
        </template>
        Edit & Book
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="params.data.is_success === 'Yes'"
            icon
            v-bind="attrs"
            v-on="on"
            @click="fetchShipmentStatus()"
          >
            <v-icon color="green">mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>Fetch Status</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click="viewDetails()">
            <v-icon color="primary">mdi-eye</v-icon>
          </v-btn>
        </template>
        View
      </v-tooltip>
    </v-col>
  </v-row>
</template>
  
  <script>
export default {
  methods: {
    viewDetails() {
      this.params.context.parentComponent.viewDetails(this.params.data.id);
    },
    editAndBook() {
      this.params.context.parentComponent.editAndBook(this.params.data);
    },
    fetchShipmentStatus() {
      this.params.context.parentComponent.fetchShipmentStatus(this.params.data);
    },
  },
};
</script>
  
  <style>
</style>