import axiosInstance from "./axios";

import auth from "../services/auth";
import dashboard from "../services/dashboard";
import organization from "@/services/organization";
import client from "@/services/client";
import user from "@/services/user";
import organizationUrl from "@/services/organizationUrl";
import vendor from "@/services/vendor";
import vendorShipments from "@/services/vendorShipments";
import inbound from "@/services/inbound";
import outbound from "@/services/outbound";
import inboundLogs from "@/services/inboundLogs";
import outboundLogs from "@/services/outboundLogs";
import userNotification from "@/services/userNotification";
import report from "@/services/report";
import webhookUpdate from "@/services/webhookUpdate";
import awbTrackAndTrace from "@/services/awbTrackAndTrace";
import organizationVendor from "@/services/organizationVendor";
import integrationPartner from "@/services/integrationPartner";
// import clientApiIntegrationPartner from "@/services/clientApiIntegrationPartner";
import vendorAPIRequestLogs from "@/services/vendorAPIRequestLogs";

export default {
  auth: auth(axiosInstance),
  dashboard: dashboard(axiosInstance),
  organization: organization(axiosInstance),
  client: client(axiosInstance),
  user: user(axiosInstance),
  organizationUrl: organizationUrl(axiosInstance),
  vendor: vendor(axiosInstance),
  vendorShipments: vendorShipments(axiosInstance),
  inbound: inbound(axiosInstance),
  outbound: outbound(axiosInstance),
  userNotification: userNotification(axiosInstance),
  inboundLogs: inboundLogs(axiosInstance),
  outboundLogs: outboundLogs(axiosInstance),
  report: report(axiosInstance),
  webhookUpdate: webhookUpdate(axiosInstance),
  awbTrackAndTrace: awbTrackAndTrace(axiosInstance),
  organizationVendor: organizationVendor(axiosInstance),
  integrationPartner: integrationPartner(axiosInstance),
  // clientApiIntegrationPartner: clientApiIntegrationPartner(axiosInstance),
  vendorAPIRequestLogs: vendorAPIRequestLogs(axiosInstance),
};
